import React, { useEffect } from "react";
import "./App.css";

const App = () => {
  useEffect(() => {
    
    const timer = setTimeout(() => {
      window.location.replace("https://insytix.com/"); // Redirect after the timer
    }, 2000); 

    // Cleanup the timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="loading-container">
      <div className="loading-content">
        <div className="spinner"></div>
        <h2>Insytix is loading...</h2>
      </div>
    </div>
  );
};

export default App;
